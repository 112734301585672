* * {
  box-sizing: border-box;
}

a,
a:link {
  color: #e68000;
}

/* @import url("https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"); */
/* @import url("https://use.typekit.net/ppq7ssa.css"); */

body {
  margin: 0;
  background-color: #252c35; /* grayXDark */
  color: white;
  font-family: Roboto;
}
